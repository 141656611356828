@import "../../variables.scss";

.calculator-button {
  margin-right: 20px;
}

.watermark_container {
  position: relative;
}
.watermark_wrapper {
  width: 100%;
  height: 100%;

  align-items: center;
  display: flex;
  justify-content: center;

  /* Absolute position */
  left: 0px;
  position: absolute;
  top: 0px;
  /* background-color: white; */
  backdrop-filter: blur(10px);
  z-index: 2;
  /* opacity: 70%; */
}
.watermark_text {
  /* Text color */
  color: rgba(0, 0, 0, 0.2);
  /* Text styles */
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;

  /* Rotate the text */
  transform: rotate(-45deg);

  /* Disable the selection */
  user-select: none;
}

.loan-bank-col {
  vertical-align: middle !important;
}

.loan-bank-logo {
  width: 80px;
  // height: 18px;
}

.loan-font-bold {
  font-weight: bold;
}

.legal-disclaimer-container {
  position: relative;
  bottom: 0
}

.legal-disclaimer {
  font-size: 6.5pt;
  line-height: 10pt;
}

.card-border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-bottom: 0px !important;
  border-left: 0px !important;
  border-top: 0px !important;
}

.card-border-none {
  border: 0px !important;
}

.card-header.calculator-card-header {
  // background-color: #facac4;
  font-weight: bold;
}

.selected-bank-title {
  font-size: 16px;
}

.selected-bank-value {
  font-size: 16px;
  font-weight: bold;
}
.calculator-result-title {
  font-size: 23px;
  font-weight: bold;
}

.calculator-result-subtitle {
  text-align: center;
  padding-top: 5px;
}

.card.calculator-upfront-total {
  top: 28%;
  border-color: $border-color-primary;
}

.calculator-financial-title {
  font-weight: bold;
  font-size: 14px;
}

.calculator-financial-content {
  font-weight: bold;
  font-size: 24px;
}

.calculator-financial-airuma-index-text {
  font-size: 14px;
}

.col-4.calculator-financial-airuma-widget-col {
  padding-left: 5px;
  // margin-right: 5px;
}

.card.calculator-financial-airuma-widget {
  min-height: 120px;
  margin-bottom: 10px;
}

.calculator-form-sum-container {
  position: absolute;
  bottom: 0;
  right: 10%;
  width: 100%;
  text-align: right;
  .sum-title {
    font-weight: bolder;
  }
}

.calculator-tnc {
  margin-top: 8px;
}

.calculator-currency {
  font-weight: bold;
}

.calculator-amount {
  font-weight: bold;
}

.get-your-report-tab {
  background-color: $tab-unique;
  
  .get-your-report-tab-title {
    color: $primary-color-dark;
  }
  .active {
    background-color: $primary-color !important;
    .get-your-report-tab-title {
      color: white;
      font-weight: bold;
    } 
  }
}
.get-your-report-tab-title {
  color: black;
  font-weight: bold;
} 

canvas {
  max-width: 400px;
  max-height: 400px;
  // min-width: 300px;
  min-height: 300px;
}

.report-subtitle {
  margin-bottom: 5px;
}

.airuma-stress-index-row-selected {
  background: rgba(255, 153, 153, 0.4)
}