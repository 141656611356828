.detailTopCard {
  border: 1px solid rgba(0,0,0,.125);
  min-height: 100px;
  width: 100%;
  display: flex;
  margin-top: 15px
}

.resultCardPropertyProperties {
  margin-right: 10px
}

