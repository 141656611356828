.firstDetailRow {
  position: relative;
}

.tabDetailRow {
  margin-top: 10px
}

.detail-footer {
  margin-top: 1rem;
  padding: 1rem;
  background-color: rgb(152, 210, 235);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
