
.filterRow {
  position: relative;
  
}

.resultRow {
  position: relative;
}

.result-card-propery-name {
  font-weight: bold;
  font-size: 18px;
}

.result-card-state {
  font-size: 14px
}

.result-card-installment {
  font-weight: bold;
  font-size: 15px;
}

.result-card-lumpsum {
  font-size: 12px;
}
