.resultCard {
  border: 1px solid rgba(0,0,0,.125);
  min-height: 100px;
  width: 100%;
  display: flex;
  margin-top: 15px;
  border-radius: .25rem;
}

.resultCardPropertyProperties {
  margin-right: 10px;
  font-size: 14px;
}


.calculation-tab-description {
  font-size: 14px
}