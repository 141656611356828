@import "../../variables.scss";


.homeCard {
  position: relative;
  /* top: 20%; */
  margin-top: -88px;
  opacity: 0.97;
}

.homeCardBox {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.banner {
  margin-top: 10px;
  height: 288px;
  width: 100%;
  background-color: $dark-background;
  text-align: center;
}

.homePageElements {
  padding-top: 50px;
  text-align: center;
}

.floating-menu {
  position: absolute;
  right: 8px;
  bottom: 50%;
  height: 42px;
  text-align: right;
  /*transition: height 250ms ease-in-out;*/
}
