@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'variables.scss';

.App {
  height: 100%
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html, body {
  height: 100%;
  font-size: 11pt;
}

.airuma-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.09), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
}

.airuma-border-primary {
  border-color: $primary-color !important;
}

.airuma-border-card-primary {
  border-color: $border-color-primary !important;
}

.airuma-border-none {
  border: none !important;
}

.airuma-navigation {
  /* background-color: #F94C00; */
  border-bottom: 2px solid $border-color-primary !important;
  /* font-weight: bold; */
}

.airuma-nav-link {
  font-weight: bold;
  /* color: #ffffff !important */
}

.airuma-nav-link:hover {
  color: $primary-color !important;
}

.airuma-page-title {
  font-size: 22px;
  font-weight: bold;
  padding: 8px 0px 8px 0px
}

.airuma-btn-primary-borderless {
  background-color: #f6988c;
  // $border-color-primary: #f05945;
  color: white;
  font-weight: bold;
  text-shadow: 1px 1px 8px $primary-color-dark;
  border-color: #f6988c;
}

.airuma-btn-primary {
  background-color: #f6988c;
  $border-color-primary: #f05945;
  color: white;
  font-weight: bold;
  text-shadow: 1px 1px 8px $primary-color-dark;
}

.airuma-btn-primary-borderless:hover, .airuma-btn-primary:hover {
  background-color: #f16957;
  $border-color-primary: #f05945;
}

.airuma-btn-primary:disabled {
  background-color: #b3b3b3;
  $border-color-primary: #f05945;
}

.airuma-btn-primary:disabled:hover {
  background-color: #c0aeab;
  $border-color-primary: #f05945;
}

.airuma-btn-info {
  background-color: #f6988c;
  border-color: $primary-color-dark;
  border-width: 1px;
  color: white;
  font-weight: bold;
  // text-shadow: 1px 1px 8px $primary-color-dark;
}

.airuma-btn-info:hover {
  background-color: #f16957;
  $border-color-primary: #f05945;
}

.airuma-btn-outline {
  background-color: white;
  color: $primary-color-dark;
}

.card-footer {
  background-color: #fcdbd6;
}

.layout-container {
  display: inline;
}


.layout-footer {
  background-color: #fdebe8;
  width: 100%;
  min-height: 30px;
  position: relative;
  bottom: 0;
}

.result-badge-container {
  margin-right: 5px;
  display: inline-block;
  border-radius: .20rem;
  border: 1px solid;
  border-color: $blue;
  padding-right: 8px;
  padding-left: 8px;
}

.result-badge {
  text-align: center;
  line-height: 1;
  font-size: 13px;
  font-weight:bolder;
}

.form-control, .input-group-text {
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 0.95rem;
}

.blinking{
  animation:blinkingText 1.9s infinite;
}
@keyframes blinkingText{
  0%{     color: #000;    }
  49%{    color: #000; }
  60%{    color: transparent; }
  99%{    color:transparent;  }
  100%{   color: #000;    }
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: $primary-color;
  border-color: $primary-color;
}

a {
  color: $primary-color-dark;
}

a:hover {
  color: $primary-color;
}

.nav-item {
  cursor: pointer;
}

.airuma-link {
  padding: 0px
}

.footer {
  background-color: rgba(179,179,179, 0.4);
  position:static;
  width: 100%;
  bottom: 0px;
  margin-top: 2rem;
  padding: 1.5rem;
  border-top: 1px solid #e7e7e7;
}

.container-fluid {
  margin-bottom: 5%;
}

.airuma-background-color {
  background-color: $primary-color-soft;
}

.airuma-border-left-primary {
  border-color: $border-color-primary !important;
  border-left: solid 1px;
}

.airuma-alert-success {
  background-color: $primary-color-soft;
  color: #6D6E71;
  font-weight: bold;
}